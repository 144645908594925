import React, { useState } from 'react';
import Payment from './Payment'; // Adjust the import path as necessary
import '../styles/SuccessMessage.css'
interface SuccessMessageProps {
  name: string;
  course: string;
  fee:number;
  admissionNumber: number;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ name, course, fee, admissionNumber }) => {
  const [showPayment, setShowPayment] = useState(false);

  const handlePayNow = () => {
    setShowPayment(true);
  };

  return (
    <div className= "success-container" >
    <div className= "success-message" >
      <h2 className="success-container h2">Thank you for your application, {name}! and your admission number is {admissionNumber}. </h2>
      <p className="success-container p">You have applied for the {course} course</p>
      <button onClick={handlePayNow} className="pay-now-button">Pay Now</button>     
    </div>
    <br></br>
    <br></br>
    <div className= "payment" >
    {showPayment && <Payment amount={fee}/>}
    </div>
    </div>
  );
};

export default SuccessMessage;
