import React, { useState, useEffect } from "react";
import axios from "axios";
import '../styles/Payment.css'
declare global {
  interface Window {
    Razorpay: any;
  }
}

interface PaymentProps {
  amount: number; // Define the type of the props
}

const Payment: React.FC<PaymentProps> = ({ amount }) => {
  const [responseId, setResponseId] = useState<string>("");
  const [responseState, setResponseState] = useState<any>(null); // Initialize as null for better type safety

  // Load the Razorpay script dynamically
  const loadScript = (src: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  // Function to create a Razorpay order
  const createRazorPayOrder = async (amount: number = 100) => {
    try {
      const data = {
        amount: amount , // Convert amount to smallest currency unit
        currency: "INR",
      };

      const config = {
        method: "post",
        url: "http://localhost:3001/create-order",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      const response = await axios.request(config);
      console.log("Order response:", response.data);
      if (response.data && response.data.order_id) {
        handleRazorPayScreen(response.data.order_id, response.data.amount);
      } else {
        alert("Failed to create order. Please check the server response.");
      }
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  // Function to handle Razorpay payment screen
  const handleRazorPayScreen = async (orderId: string, amount: number) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Failed to load Razorpay SDK. Are you online?");
      return;
    }

    const options = {
      key: "rzp_test_GcZZFDPP0jHtC4",
      amount: amount,
      currency: "INR",
      name: "Adhyayan Institute of Technology and Management",
      description: "Admission fee",
      image: "https://aitm.academy",
      order_id: orderId, // Use the generated order ID
      handler: (response: any) => {
        alert(`Payment ID: ${response.razorpay_payment_id}`);
        setResponseId(response.razorpay_payment_id);
      },
      prefill: {
        name: "AITM",
        email: "aitm.help@gmail.com",
        contact: "9302776330",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // Fetch payment details
  const paymentFetch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const paymentId = e.currentTarget.paymentId.value; // Use e.currentTarget to access form elements
    try {
      const response = await axios.get(`http://localhost:3001/payment/${paymentId}`);
      console.log(response.data);
      setResponseState(response.data);
    } catch (error) {
      console.error("Error occurred while fetching payment:", error);
    }
  };

  // Call createRazorPayOrder when the component mounts
  useEffect(() => {
    createRazorPayOrder(amount);
  }, [amount]); // Dependency array includes amount

  return (
    <div className="payment-container">
      <h2 className="payment-container h2">This is a payment verification form</h2>
      {responseId && <p>Payment ID: {responseId}</p>}
      <br />
      <form onSubmit={paymentFetch}>
        <input  type="text" name="paymentId" placeholder="Enter Payment ID" required />
        <button className="fetch-button" type="submit">Fetch payment</button>
        {responseState && (
          <ul>
            <li>Amount: {(responseState.amount).toFixed(2)} Rs.</li>
            <li>Currency: {responseState.currency}</li>
            <li>Status: {responseState.status}</li>
            <li>Method: {responseState.method}</li>
          </ul>
        )}
      </form>
    </div>
  );
};

export default Payment;
