// import React, { useState } from 'react';
// import axios from 'axios';

// // Define the type for the sign-up form
// interface SignUpFormData {
//   name: string;
//   email: string;
//   password: string;
//   accessLevel: 'basic' | 'premium';
// }

// const SignUp: React.FC = () => {    
//   const [formData, setFormData] = useState<SignUpFormData>({
//     name: '',
//     email: '',
//     password: '',
//     accessLevel: 'basic',
//   });

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   // Handle input changes
//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Handle form submission
//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await axios.post('http://localhost:3001/Sign-up', formData);
//       console.log('User registered successfully:', response.data);
//       alert('Registration successful!');
//       // Optionally redirect or perform additional actions
//     } catch (err) {
//       setError('Failed to register. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div style={{ maxWidth: 400, margin: '0 auto', padding: '1em' }}>
//       <h2>Sign Up</h2>
//       <form onSubmit={handleSubmit} style={formStyles}>
//         <input
//           type="text"
//           name="name"
//           placeholder="Name"
//           value={formData.name}
//           onChange={handleChange}
//           required
//           style={inputStyles}
//         />
//         <input
//           type="email"
//           name="email"
//           placeholder="Email"
//           value={formData.email}
//           onChange={handleChange}
//           required
//           style={inputStyles}
//         />
//         <input
//           type="password"
//           name="password"
//           placeholder="Password"
//           value={formData.password}
//           onChange={handleChange}
//           required
//           style={inputStyles}
//         />
//         <select
//           name="accessLevel"
//           value={formData.accessLevel}
//           onChange={handleChange}
//           required
//           style={inputStyles}
//         >
//           <option value="basic">Basic Access</option>
//           <option value="premium">Premium Access</option>
//         </select>

//         <button type="submit" style={buttonStyles} disabled={loading}>
//           {loading ? 'Registering...' : 'Sign Up'}
//         </button>

//         {error && <p style={errorStyles}>{error}</p>}
//       </form>
//     </div>
//   );
// };

// // Styles for form elements (adjust as needed for mobile)
// const formStyles: React.CSSProperties = {
//   display: 'flex',
//   flexDirection: 'column',
//   gap: '1em',
// };

// const inputStyles: React.CSSProperties = {
//   padding: '0.75em',
//   fontSize: '1em',
//   borderRadius: '4px',
//   border: '1px solid #ccc',
//   width: '100%',
// };

// const buttonStyles: React.CSSProperties = {
//   padding: '0.75em',
//   fontSize: '1em',
//   borderRadius: '4px',
//   border: 'none',
//   backgroundColor: '#4CAF50',
//   color: 'white',
//   cursor: 'pointer',
// };

// const errorStyles: React.CSSProperties = {
//   color: 'red',
//   fontSize: '0.875em',
// };

// export default SignUp;

import React, { useState } from 'react';
import axios from 'axios';

// Define the type for the form
interface FormData {
  name?: string;
  email: string;
  password: string;
  accessLevel?: 'basic' | 'premium';
}

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    password: '',
    accessLevel: 'basic',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [mode, setMode] = useState<'signUp' | 'login'>('login'); // Mode state

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Toggle between sign-up and login
  const toggleMode = () => {
    setMode(mode === 'signUp' ? 'login' : 'signUp');
    setFormData({ email: '', password: '' }); // Reset form for login
    setError(null);
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const url = mode === 'signUp' ? 'http://localhost:3001/Sign-up' : 'http://localhost:3001/Login';
      const response = await axios.post(url, formData);
      console.log(`${mode === 'signUp' ? 'User registered' : 'User logged in'} successfully:`, response.data);
      alert(`${mode === 'signUp' ? 'Registration' : 'Login'} successful!`);
      // Optionally redirect or perform additional actions
    } catch (err) {
      setError(`Failed to ${mode === 'signUp' ? 'register' : 'login'}. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: '0 auto', padding: '1em' }}>
      <h2>{mode === 'signUp' ? 'Sign Up' : 'Login'}</h2>
      <form onSubmit={handleSubmit} style={formStyles}>
        {mode === 'signUp' && (
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name || ''}
            onChange={handleChange}
            required
            style={inputStyles}
          />
        )}
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
          style={inputStyles}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
          style={inputStyles}
        />
        {mode === 'signUp' && (
          <select
            name="accessLevel"
            value={formData.accessLevel}
            onChange={handleChange}
            required
            style={inputStyles}
          >
            <option value="basic">Basic Access</option>
            <option value="premium">Premium Access</option>
          </select>
        )}
        <button type="submit" style={buttonStyles} disabled={loading}>
          {loading ? (mode === 'signUp' ? 'Registering...' : 'Logging in...') : mode === 'signUp' ? 'Sign Up' : 'Login'}
        </button>
        {error && <p style={errorStyles}>{error}</p>}
      </form>

      {/* Toggle between Sign Up and Login */}
      <p style={{ textAlign: 'center', marginTop: '1em' }}>
        {mode === 'signUp' ? (
          <span>
            Already have an account? <button onClick={toggleMode} style={linkStyles}>Login</button>
          </span>
        ) : (
          <span>
            Don't have an account? <button onClick={toggleMode} style={linkStyles}>Sign Up</button>
          </span>
        )}
      </p>
    </div>
  );
};

// Styles for form elements
const formStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
};

const inputStyles: React.CSSProperties = {
  padding: '0.75em',
  fontSize: '1em',
  borderRadius: '4px',
  border: '1px solid #ccc',
  width: '100%',
};

const buttonStyles: React.CSSProperties = {
  padding: '0.75em',
  fontSize: '1em',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: '#4CAF50',
  color: 'white',
  cursor: 'pointer',
};

const errorStyles: React.CSSProperties = {
  color: 'red',
  fontSize: '0.875em',
};

const linkStyles: React.CSSProperties = {
  background: 'none',
  color: '#4CAF50',
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
};

export default SignUp;
