// Home.tsx
import React from "react";
import { SITE_SETTINGS } from "../settings";
import '../styles/App.css'

const Home: React.FC = () => (
    <div>
        <h1 className="form-title">Welcome to {SITE_SETTINGS.name}</h1>
        <p className="form-content">Your future in technology and management starts here!</p>
    </div>
);

export default Home;

