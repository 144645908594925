
//--------------------------------------
import React, { useState } from 'react';
import '../styles/Admission.css';
import SuccessMessage from './SuccessMessage';
import {useTranslation} from 'react-i18next'

interface DocumentRequirements {
  tenth: boolean;
  twelfth: boolean;
  graduation: boolean;
}
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  gender: string;
  address: string;
  highSchoolName: string;
  highSchoolPercentage: number | '';
  higherSSchoolName: string;
  higherSSchoolPercentage: number | '';
  graduationName: string;
  graduationPercentage: number | '';
  course: string;
  tenthProof?: File | null;
  twelfthProof?: File | null;
  graduationProof?: File | null;
}

// Unified course information object
const courseInfo: {
  [key: string]: {
    fee: number;
    documents: { tenth: boolean; twelfth: boolean; graduation: boolean };
  };
} = {
 
  Cloud: { fee: 20000, documents: { tenth: false, twelfth: false, graduation: false } },
  "DevOps": { fee: 20000, documents: { tenth: false, twelfth: false, graduation: false } },
  "AI Programming": { fee: 40000, documents: { tenth: false, twelfth: false, graduation: false } },
  "Web App Development": { fee: 25000, documents: { tenth: false, twelfth: false, graduation: false } },
  "Backend Development(API)": { fee: 50000, documents: { tenth: true, twelfth: true, graduation: false } },
  "Database Management": { fee: 25000, documents: { tenth: true, twelfth: false, graduation: true } },
  "BE(Computer)": { fee: 25000, documents: { tenth: true, twelfth: false, graduation: false } },
  "B.Tech (Comp.)": { fee: 300000, documents: { tenth: true, twelfth: true, graduation: false } },
  "M.Sc(IT)": { fee: 18000, documents: { tenth: true, twelfth: false, graduation: false } },
  PGDCA: { fee: 20000, documents: { tenth: true, twelfth: true, graduation: true } },
};

const Admission: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    address: '',
    highSchoolName: '',
    highSchoolPercentage: '',
    higherSSchoolName: '',
    higherSSchoolPercentage: '',
    graduationName: '',
    graduationPercentage: '',
    course: '',
    tenthProof: null,
    twelfthProof: null,
    graduationProof: null,
  });

  const [selectedCourseFee, setSelectedCourseFee] = useState<number | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [admissionNumber, setAdmissionNumber] = useState<number | null>(null);
  const {t} =useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'highSchoolPercentage' || name === 'graduationPercentage' ? Number(value) || '' : value,
    }));

    if (name === 'course') {
      setSelectedCourseFee(courseInfo[value]?.fee || null);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    }
    if (e.target.files && e.target.files[0]) {
     //handleFileUpload(e.target.files[0]); // commited as azure is down
    }
  };
  
  

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   console.log("Form Submitted", formData);
    
   
  // };
  const handleAdmissionAPI = async (data: FormData) => {
    try {
        const response = await fetch('http://localhost:3001/submitAdmission', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            const result = await response.json();
            return result; // Return the result to be handled by handleSubmit
        } else {
            console.error('Submission failed:', response.statusText);
            return null; // Return null if submission failed
        }
    } catch (error) {
        console.error('Error during submission:', error);
        return null; // Return null if there was an error
    }
};

const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    handleAdmissionAPI(formData).then((result) => {
        if (result) {
            setAdmissionNumber(result.admissionNumber);
            setSubmitted(true);
        }
    });
};

  // Get required documents for the selected course
  const requiredDocuments: DocumentRequirements = formData.course ? courseInfo[formData.course]?.documents || { tenth: false, twelfth: false, graduation: false } : { tenth: false, twelfth: false, graduation: false };
  //
  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch('http://localhost:3001/upload', {
            method: 'POST',
            body: formData,
        });
        if (response.ok) {
            const result = await response.json();
            console.log('Upload successful:', result);
        } else {
            console.error('Upload failed:', response.statusText);
        }
    } catch (error) {
        console.error('Error during upload:', error);
    }
};

//
  return (
    <div className="admission-container">
      <h2 className="form-title">{t("admissionTitle")}</h2>
      {submitted ? (
        <SuccessMessage name={`${formData.firstName} ${formData.lastName}`} course={formData.course} fee={selectedCourseFee || 0} admissionNumber={admissionNumber || 0} />
      ) : (
        <form className="admission-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="course">Preferred Course:</label>
            <select
              id="course"
              name="course"
              value={formData.course}
              onChange={handleChange}
              required
              className="form-control-dpd"
            >
              <option value="">Select</option>
              {Object.keys(courseInfo).map((course) => (
                <option key={course} value={course}>{course}</option>
              ))}
            </select>
          </div>

          {formData.course && (
            <div className="course-fee-group">
              <label htmlFor="courseFee">Course Fee:</label>
              <input
                type="text"
                id="courseFee"
                value={`₹${selectedCourseFee}`}
                readOnly
                className="course-fee-input"
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender:</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              className="form-control-dpd"
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              className="form-control textarea"
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="highSchoolName">High School Name(10th):</label>
            <input
              type="text"
              id="highSchoolName"
              name="highSchoolName"
              value={formData.highSchoolName}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="highSchoolPercentage">High School Percentage:</label>
            <input
              type="number"
              id="highSchoolPercentage"
              name="highSchoolPercentage"
              value={formData.highSchoolPercentage || ''}
              onChange={handleChange}
              required
              className="form-control"
              min="0"
              max="100"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label htmlFor="higherSSchoolName">Higher Secondary School Name(12th):</label>
            <input
              type="text"
              id="higherSSchoolName"
              name="higherSSchoolName"
              value={formData.higherSSchoolName}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="higherSchoolPercentage">Higher Secondary School Percentage:</label>
            <input
              type="number"
              id="higherSSchoolPercentage"
              name="higherSSchoolPercentage"
              value={formData.higherSSchoolPercentage || ''}
              onChange={handleChange}
              required
              className="form-control"
              min="0"
              max="100"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label htmlFor="graduationName">Graduation School/University:</label>
            <input
              type="text"
              id="graduationName"
              name="graduationName"
              value={formData.graduationName}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="graduationPercentage">Graduation Percentage:</label>
            <input
              type="number"
              id="graduationPercentage"
              name="graduationPercentage"
              value={formData.graduationPercentage || ''}
              onChange={handleChange}
              className="form-control"
              min="0"
              max="100"
              step="0.01"
            />
          </div>

          <div className="form-group">
            <label htmlFor="tenthProof">10th Proof Document {requiredDocuments.tenth ? "(Mandatory)" : "(Optional)"}:</label>
            <input
              type="file"
              id="tenthProof"
              name="tenthProof"
              onChange={handleFileChange}
              required={requiredDocuments.tenth}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="twelfthProof">12th Proof Document {requiredDocuments.twelfth ? "(Mandatory)" : "(Optional)"}:</label>
            <input
              type="file"
              id="twelfthProof"
              name="twelfthProof"
              onChange={handleFileChange}
              required={requiredDocuments.twelfth}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="graduationProof">Graduation Proof Document {requiredDocuments.graduation ? "(Mandatory)" : "(Optional)"}:</label>
            <input
              type="file"
              id="graduationProof"
              name="graduationProof"
              onChange={handleFileChange}
              required={requiredDocuments.graduation}
              className="form-control"
            />
          </div>

          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
};

export default Admission;
