
// import React from "react";
// import { SITE_SETTINGS } from "../settings.ts";
// import '../styles/App.css'

// const About: React.FC = () => (
//     <div>
//         <h1 className="form-title">About {SITE_SETTINGS.name}</h1>
//         <p className="form-content">
//             {SITE_SETTINGS.name} is a premier institute dedicated to empowering students with cutting-edge tech skills and industry-relevant knowledge. Our comprehensive curriculum covers a range of modern technical domains, including Software Development, Cloud and DevOps, Cybersecurity, AI, Data Science, and Web Development. 
//             <br /><br />
//             Through live project-based training and hands-on internships, we ensure our students are well-prepared for real-world challenges. Our expert-led sessions emphasize practical skills in essential technologies like React, Node.js, .NET Core, and cloud platforms (Azure, AWS, Google Cloud). 
//             <br /><br />
//             Additionally, we offer specialized academic programs such as PGDCA, DCA, BCA, B.Sc. (IT), and M.Sc. (IT/Computer Science), designed to build strong foundations and guide students toward success in the tech industry. With comprehensive job placement support, interview preparation, and personalized mentorship, {SITE_SETTINGS.name} is your partner in building a career in the digital age.
//         </p>
//     </div>
// );

// export default About;

// About.tsx
import React from "react";
import { SITE_SETTINGS } from "../settings";
import '../styles/App.css'

const About: React.FC = () => (
    <div>
        <h1 className="form-title">About {SITE_SETTINGS.name}</h1>
        <p className="form-content">
            {SITE_SETTINGS.name} is a premier institute dedicated to empowering students with cutting-edge tech skills and industry-relevant knowledge. Our comprehensive curriculum covers a range of modern technical domains, including Software Development, Cloud and DevOps, Cybersecurity, AI, Data Science, and Web Development.
            <br /><br />
            Additionally, we offer specialized academic preparation programs such as BE(Comp.), B.Tech(Comp.), M.Sc(IT), MCA, BCA and PGDCA designed to build strong foundations and guide students toward success in the tech industry. With comprehensive job placement support, interview preparation, and personalized mentorship, {SITE_SETTINGS.name} is your partner in building a career in the digital age.
        </p>
        
        <h4 className="form-title">Our Locations</h4>
        <div className="location-container">
            <div className="location-box">
                <h4>Gurugram Office</h4>
                <p>H.N. 301/D4, main pataudi road, Sector 89, Gurugram - 122505, India </p>
            </div>
            <div className="location-box">
                <h4>Bhopal Office</h4>
                <p>Ground Floor, Kushwaha Complex, Bhadbhada Road, Neelbad, Bhopal(MP) - 462044 (Near Neelbad Chouraha), India </p>
            </div>
            <div className="location-box">
                <h4>UK Office</h4>
                <p>11 The Pinnacle, Kings Road, Reading, RG1 4LY, England, United kingdom </p>
            </div>
            <div className="location-box">
                <h4>USA Office</h4>
                <p>2812 Owl Head Dr, Leander, Texas (TX) 7864, United States (USA) </p>
            </div>
            
        </div>
    </div>
);

export default About;
