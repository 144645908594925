// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Admission from "./components/Admission";
import PaymentReceipt from './components/Receipt'
import Programs from './components/Programs'
import SignUp from './components/SignUp'
import "./styles/App.css";

const App: React.FC = () => {
    return (
        <Router>
            <NavBar />
            <main className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/admissions" element={<Admission />} />
                    <Route path="/payment" element={< PaymentReceipt  admissionNumber={1010}  studentName={'Amit'} courseName={'Cloud'} courseFee={20000} admissionDate={'20/11/2024'} gstRate={17} />} />
                    <Route path="/programs" element={< Programs/>} />
                    <Route path="/login" element={<SignUp/>} />
                    {/* Add more routes as needed */}
                </Routes>
            </main>
        </Router>
    );
};

export default App;
