// settings.ts
export const SITE_SETTINGS = {
  name: "Adhyayan Institute of Technology and Management(AITM)",
  shortName:"AITM",
  intialName:"Adhyayan Institute",
  lastName:" of Technology and Management",
  fullName:"Adhyayan Institute of Technology and Management",
  navLinks: [
      { name: "Home", path: "/" },    
      { name: "Admissions", path: "/admissions" },
      { name: "Programs", path: "/programs" },
      { name: "About", path: "/about" },
      { name: "Contact", path: "/contact" },
      { name: "Login", path: "/login" },
      // { name: "Payment", path: "/payment" }
  ]
};
