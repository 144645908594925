import React, { useMemo } from 'react';
import '../styles/PaymentReceipt.css';

interface ReceiptProps {
  admissionNumber: number;
  studentName: string;
  courseName: string;
  courseFee: number;
  admissionDate: Date | string; // Accept both Date and string types
  gstRate?: number;              // Optional GST rate with default value
}

const Receipt: React.FC<ReceiptProps> = ({
  admissionNumber,
  studentName,
  courseName,
  courseFee,
  admissionDate,
  gstRate = 18,
}) => {
  // Calculate GST and total amount using useMemo
  const { gstAmount, totalAmount } = useMemo(() => {
    const gstValue = (courseFee * gstRate) / 100;
    return {
      gstAmount: gstValue,
      totalAmount: courseFee + gstValue,
    };
  }, [courseFee, gstRate]);

  const handlePrint = () => {
    window.print();
  };

  // Format admission/payment date
  const formattedDate = useMemo(() => {
    const date = new Date(admissionDate);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(date);
  }, [admissionDate]);

  return (
    <div className="receipt-container">
      <h2>Admission Receipt</h2>
      <p><strong>Admission Number:</strong> {admissionNumber}</p>
      <p><strong>Student Name:</strong> {studentName}</p>
      <p><strong>Course Name:</strong> {courseName}</p>
      <p><strong>Admission Date:</strong> {formattedDate}</p>
      <p><strong>Course Fee:</strong> ₹{courseFee.toFixed(2)}</p>
      <p><strong>GST ({gstRate}%):</strong> ₹{gstAmount.toFixed(2)}</p>
      <p><strong>Total Amount Paid:</strong> ₹{totalAmount.toFixed(2)}</p>

      <button onClick={handlePrint} className="print-button">Print Receipt</button>
    </div>
  );
};

export default Receipt;
