import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Programs.css'; // Import the CSS file


interface Program {
  training: string[];
  courses: {
    category: string;
    items: string[];
  }[];
}

const Programs: React.FC = () => {
  const [programData, setProgramData] = useState<Program | null>(null);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get<Program>('/programs.json');
        setProgramData(response.data);
      } catch (error) {
        console.error("Error fetching program data:", error);
      }
    };
    fetchPrograms();
  }, []);

  if (!programData) return <p>Loading programs...</p>;
  return (
    <div className="programs-container">
      <h1 className="main-header">Courses Offered</h1>
      
      <div className="content-box">
        <h2 className="section-header">Live Project Training & Internship</h2>
        <ul className="programs-list">
          {programData.training.map((item, index) => (
            <li key={index} className="programs-list-item">{item}</li>
          ))}
        </ul>
       
        {programData.courses.map((course, index) => (
          <div key={index} className="category-container">
            <h3 className="category-header">{course.category}</h3>
            <ul className="programs-list">
              {course.items.map((item, i) => (
                <li key={i} className="programs-list-item">{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
