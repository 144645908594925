import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SITE_SETTINGS } from "../settings";
import "../styles/NavBar.css";
import logo from "../assets/LogoAITM.png"; // Adjust the path if necessary
import { useTranslation } from "react-i18next";
import SignUp from "../components/SignUp"; // Import SignUp component

const NavBar: React.FC = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false); // State to control SignUp visibility
    const { i18n, t } = useTranslation();

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value);
    };

    // Toggle login/logout or show SignUp
    const handleAuthToggle = () => {
        if (!loggedIn) {
            setShowSignUp(true); // Show SignUp if not logged in
        } else {
            setLoggedIn(false); // Logout user if logged in
        }
    };

    return (
        <header className="navbar">
            <div className="navbar-brand">
                {/* <img src={logo} alt="Logo" className="navbar-logo" /> */}
                <div className="navbar-title">
                    <span className="navbar-shortName">{SITE_SETTINGS.shortName}</span>
                    <span className="navbar-fullName">{SITE_SETTINGS.fullName}</span>
                </div>
                <button className="navbar-toggle" onClick={toggleMobileMenu}>
                    ☰
                </button>
            </div>

            <nav className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
                {SITE_SETTINGS.navLinks.map((link) => (
                    <Link key={link.name} to={link.path} className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
                        {link.name}
                    </Link>
                ))}
            </nav>

            {/* Language Selector */}
            <div className="navbar-language-selector">
                <label htmlFor="language-select" className="language-label">{t("language")}:</label>
                <select id="language-select" onChange={handleLanguageChange} defaultValue={i18n.language}>
                    <option value="en">English</option>
                    <option value="hi">हिंदी</option>
                </select>
            </div>

        </header>
    );
};

export default NavBar;
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { SITE_SETTINGS } from "../settings.ts";
// import "../styles/NavBar.css";
// import logo from "../assets/LogoAITM.png"; // Adjust the path if necessary
// import { useTranslation } from "react-i18next";
// import SignUp from "../components/SignUp"; // Import SignUp component

// const NavBar: React.FC = () => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [showSignUp, setShowSignUp] = useState(false); // State to control SignUp visibility
//   const { i18n, t } = useTranslation();

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     i18n.changeLanguage(event.target.value);
//   };

//   // Toggle login/logout or show SignUp
//   const handleAuthToggle = () => {
//     if (!loggedIn) {
//       setShowSignUp(true); // Show SignUp if not logged in
//     } else {
//       setLoggedIn(false); // Logout user if logged in
//     }
//   };

//   return (
//     <header className="navbar">
//       <div className="navbar-brand">
//         <div className="navbar-title">
//           <span className="navbar-shortName">{SITE_SETTINGS.shortName}</span>
//           <span className="navbar-fullName">{SITE_SETTINGS.fullName}</span>
//         </div>
//         <button className="navbar-toggle" onClick={toggleMobileMenu}>
//           ☰
//         </button>
//       </div>

//       <nav className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
//         {SITE_SETTINGS.navLinks.map((link) => (
//           <Link key={link.name} to={link.path} className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
//             {link.name}
//           </Link>
//         ))}
//         {/* Admission dropdown */}
//         <div className="admission-dropdown">
//           <button className="nav-link">
//             Admission
//           </button>
//           <div className="admission-submenu">
//             <Link to="/admission/receipt" className="nav-link">
//               Payment Receipt
//             </Link>
//           </div>
//         </div>
//       </nav>

//       {/* Language Selector */}
//       <div className="navbar-language-selector">
//         <label htmlFor="language-select" className="language-label">{t("language")}:</label>
//         <select id="language-select" onChange={handleLanguageChange} defaultValue={i18n.language}>
//           <option value="en">English</option>
//           <option value="hi">हिंदी</option>
//         </select>
//       </div>
//     </header>
//   );
// };

// export default NavBar;

