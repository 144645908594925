

import React from 'react';
import "../styles/Contact.css"; // Import your CSS file for styling (optional)

const Contact: React.FC = () => {
    return (
        <div className="contact-container">
            <h2 className="form-title">Contact Us</h2>
            <div className="contact-details">
                <div className="contact-item">
                    <strong>Email:</strong> <a href="mailto:aitm.help@gmail.com">aitm.help@gmail.com</a>
                </div>
                <div className="contact-item">
                    <strong>Site URL:</strong> <a href="https://www.aitm.academy/" target="_blank" rel="noopener noreferrer">aitm.academy</a>
                </div>
                <div className="contact-item">
                    <strong>YouTube Channel URL:</strong> <a href="https://www.youtube.com/@bbi-edu" target="_blank" rel="noopener noreferrer">https://www.youtube.com/@bbi-edu</a>
                </div>
                <div className="contact-item">
                    <strong>Phone:</strong> <a href="tel:+91-9522933330">+91-9522933330</a>
                </div>
                <div className="contact-item">
                    <strong>WhatsApp:</strong> <a href="https://wa.me/919302776330" target="_blank" rel="noopener noreferrer">+91-9302776330</a>
                </div>
                <div className="contact-item">
                    <strong>Address:</strong> Gr. Floor, Kushwaha Complex Bhadbada road, Needbad Chauraha, Bhopal-462044
                </div>
            </div>
        </div>
    );
};

export default Contact;

